<template>
  <div class="hosts-page">
    <Pane>
     <HostsOverview />
    </Pane>
  </div>
</template>

<script>
import Pane from '@/components/decorative/Pane.vue';
import HostsOverview from '@/components/HostsOverview.vue';

export default {
  name: 'home',
  components: {
    Pane,
    HostsOverview,
  },
};

</script>
