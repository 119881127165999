<template>
  <div class="checkbox"
    :class="{'checkbox--checked': val}"
    @click="clickEvt"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    val: [Boolean, Number, null],
  },
  methods: {
    clickEvt(evt) {
      if (evt.target.localName === 'a') return;
      this.$emit('update:val', !this.val);
    },
  },
};
</script>

<style lang="scss">
  @import "@/styles/variables";

  .checkbox {
    padding: 0 0 5px 35px;
    line-height: 1.4;
    background-image: url('/icons/unchecked.svg');
    background-repeat: no-repeat;
    cursor: pointer;
    user-select: none;

    &--checked {
      background-image: url('/icons/checked.svg');
    }
  }
</style>
